// Vendors
import React from 'react';
// Components
import { ErrorComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  PERSONAL_NO_DOCUEMNTS_PROPS,
  SEND_BUTTON_TEST_ID,
} from './constants/personal-no-documents.constants';
// Contexts
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Styled
import { PersonalUploadNoDocumentationComponentWrapperStyled } from '../../personal-upload-documentation.view.styled';
// Translations
import {
  NO_DOCUMENTS_TITLE,
  NO_DOCUMENTS_BUTTON_DESCRIPTION,
} from './translations/personal-no-documents.translations';
// Utilities
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

const PersonalNoDocumentsComponent = (): React.ReactElement => {
  const { onCancelHybridFlow } = MobileConnectorContextConsumerHook();

  return (
    <PersonalUploadNoDocumentationComponentWrapperStyled>
      <ErrorComponent
        {...PERSONAL_NO_DOCUEMNTS_PROPS}
        buttonConfiguration={{
          children: <FormattedMessageComponent id={NO_DOCUMENTS_BUTTON_DESCRIPTION} />,
          onClick: () =>
            onCancelHybridFlow(generateAbsoluteRoute(RoutesEnumeration.PERSONAL_INFORMATION)),
          testId: SEND_BUTTON_TEST_ID,
        }}
        title={<FormattedMessageComponent id={NO_DOCUMENTS_TITLE} />}
      />
    </PersonalUploadNoDocumentationComponentWrapperStyled>
  );
};

export { PersonalNoDocumentsComponent };
